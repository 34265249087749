@import "../../../../css/extends/variables";
@import "../../../../css/velonic/variables";

.legend-item {
  display: inline-block;
  margin-right: 12px;

  .circle {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 6px;
    vertical-align: middle;
  }

  .mapped {
    background: $mapped;
  }

  .matched {
    background: $matched;
  }

  .unmatched {
    border: 1px solid $light-gray;
  }

  .pending {
    background: $primary;
  }

  .deleted {
    background: $danger;
  }

  .legend-name {
    display: inline-block;
    vertical-align: middle;
  }
}
