/*
 * Copyright MediaCT. All rights reserved.
 * https://www.mediact.nl
 */

#wrapper {
  .notification-list {
    button, button:focus, button:hover, button:active {
      border: 0;
      background: 0;
      background-color: transparent !important;
      box-shadow: none;
    }
    .noti-icon-badge {
      top: 14px;
    }
  }
  .show > .dropdown-menu {
    margin-top: 0px !important;
  }
}
