@import "../../css/extends/variables";
@import url('https://fonts.googleapis.com/css?family=Lato:400,700|Montserrat:500,600');

// Color variables

$white: #ffffff;
$default: #dae6ec;
$primary: $magement-blue;
$success: #78A857;
$info: #33b0e0;
$warning: #edc755;
$danger: #d03f3f;
$purple: #716cb0;
$pink: #f24f7c;
$inverse: #36404c;
$muted: #98a6ad;
$light: #e2e2e2;
$light3: #f3f3f3;
$dark: #313a46;

// Body Background
$bg-body: #edf0f0;

// Shadow
$shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);

//Background left-sidebar
$bg-leftbar: $magement-blue;
$menu-item-color: $light-gray;

//Background Topbar
$bg-topbar: #ffffff;

// Width variables
$width: 100%;

// Height variables
$height: 100%;

//Font variables
$font-primary: 'Lato', sans-serif;
$font-secondary: 'Montserrat', sans-serif;

//Font Base
$font-size-base: 14px;

// Font weight
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-semi-bold: 600;

