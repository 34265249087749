.menu .clickable {
    cursor: pointer;
    background: none;
    border: 0;
    width:100%;
    outline: none;
}

.menu .clickable:focus {
    outline: none;
}

.enlarged #sidebar-menu {}
