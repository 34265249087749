@import "../../../css/extends/variables";

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker-wrapper {
  width: 100%;

    input {
      border: 1px solid $light-gray;
      border-radius: 4px;
      padding: 7px 12px;
      height: 38px;
      max-width: 100%;
      font-size: 14px;
      box-shadow: none;
      transition: all 300ms linear;
      width: 100%;
    }
}

.react-datepicker-popper {
  min-width: 350px;
}
