form.columns {
    font-size:14px;
    white-space: nowrap;
}

form.columns .form-group {
    margin-bottom: 4px;
}

form.columns label > span {
    margin-left:14px;
}