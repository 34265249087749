@import "../../../css/velonic/variables";
@import "../../../css/extends/variables";

.health-widgets {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

  button:hover, button:active {
    text-decoration: none;
  }
}

.health-report {
  text-align: center;
  .placeholder {
    color: $light-gray;
  }

  p {
    font-family: $font-secondary;
    text-transform: uppercase;
    color: $muted;
  }
}

.toggle-report-button {
  display: block !important;
  margin: 0 auto;
}

