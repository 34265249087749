.realtime-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 24px;

  .widget {
    display: flex;
    border-radius: 4px;

    div:first-child {
      flex-grow: 1;
    }

    .icon {
      width: 110px;
      background: hsla(0, 0%, 100%, .2);
      display: flex;
      justify-content: center;

      .align-vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      img {
        height: 30px;
      }
    }

    .last-update {
      font-size: 0.6rem;
      display: none;
      position: absolute;
    }
  }

  .widget:hover {
    .last-update {
      display: block;
    }
  }
}

