.realtime {
  p {
    text-align: right;

    @media (max-width: 768px) {
      text-align: left;
      margin-top: 12px;
      margin-bottom: 6px;
    }
  }
}

.time-frame {
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    display: block;

    .date-range-select {
      margin-top: 24px;
      margin-left: 0 !important;
    }
  }

}
