pre {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
}

.has-children {
    padding: 0 !important;
}

.has-children ul li {
    border-left: 0;
    border-right: 0;
}

.has-children ul li:first-child {
    border-top: 0;
}

.has-children ul li:last-child {
    border-bottom: 0;
}

.has-children table tr:first-child td {
    border-top: 0;
}

.has-children table tr:last-child td {
    border-bottom: 0;
}

.has-children table tr td:first-child {
    border-left: 0;
}

.has-children table tr td:last-child {
    border-right: 0;
}