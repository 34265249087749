.chips {
    padding: 0;
    margin: 0;
}

.chips li {
    list-style: none;
    float: left;
}

.chip span:after {
    content: "x";
    margin-left: 8px;
}
