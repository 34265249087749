@import "../../css/velonic/variables";

.wrapper-page {
  background: $inverse;
}

.account-pages {
  .btn-login {
    border: 0;
    background: 0;
    padding: 0;
    cursor: pointer;
  }

  .btn-login:disabled {
    img {
      opacity: .6;
    }
  }

  .account-logo-box  {
    img {
      margin: 1.6em auto 0.6em;
      display: block;
    }
  }
}
