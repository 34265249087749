@import "../../../css/velonic/variables";
@import "../../../css/extends/variables";

.matched {
  color: $warning;
}

.mapped {
  color: $success-secondary;
}

.mapping-filter {
  .checkbox-inline {
    margin-right: 14px;
    cursor: pointer;

    span {
      margin-left: 6px;
    }
  }
}

.mapping-table {
  thead, tbody {
    .source, .destination {
      width: 45%;
    }
    .actions {
      width:10%;
      text-align: right;
    }
  }
}
