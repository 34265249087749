.rotate {
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}

/*
 * Completely remove all backgrounds and
 * borders from a button that has icons.
 */
.iconButton,
.iconButton:hover,
.iconButton:active,
.iconButton:focus {
    background: transparent none !important;
    border: 0 !important;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
