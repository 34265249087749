.pagination .current-page {
    border-radius: 0;
    width: 58px;
    text-align:center;
    font-size: 14px;
    border-color: #6c757d;
    border-left:0;
    border-right:0;
}

.pagination .btn-outline-secondary:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.pagination .btn-outline-secondary:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}